import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import get from "lodash.get";

import Btn, { btnFields } from "app/components/btn";
import { DoodleCircle } from "app/components/icons";
import Image, { imageField } from "app/components/image";
import Link from "app/components/link";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as S from "app/components/modular-content/modular-content.styles";

const FeatureImage = ({ image, imageUrl, cta, doodles }) => (
  <Link
    className="group relative z-10 flex h-[310px] w-[310px] items-center justify-center overflow-visible rounded-full "
    optional
    {...cta}
  >
    {doodles && (
      <DoodleCircle className="absolute left-[-20%] top-0 h-[110%] w-full text-white" />
    )}
    <div
      className={
        "relative z-10 h-[310px] w-[310px] overflow-hidden rounded-full bg-off-white"
      }
    >
      <Image
        className="h-full w-full object-cover"
        image={image}
        imageUrl={imageUrl}
      />
      {get(cta, "label") && (
        <span className="invisible absolute inset-0 z-0 h-full w-full group-hover:visible">
          <span className="absolute top-2/3 left-0 z-20 w-full px-2 text-center text-base font-bold uppercase text-white">
            {get(cta, "label")}
          </span>
          <span className="absolute inset-0 z-10 h-full w-full bg-[rgba(41,41,41,0.4)] mix-blend-darken" />
        </span>
      )}
    </div>
  </Link>
);

const themes = {
  red: ["red gray-button"],
  gray: ["gray"],
  white: ["white"],
  "gray-grid": ["gray grid-bg"],
  "white-grid": ["white grid-bg"],
};

const FeatureBlock = ({
  doodles,
  featureImages,
  title,
  titleMaxWidth,
  subtitle,
  subtitleMaxWidth,
  text,
  cta,
  variation,
}) => (
  <section css={S.moduleTheme} className={get(themes, variation)}>
    <div className="my-0 mx-auto flex w-full max-w-7xl flex-col items-center justify-center py-20 text-center">
      <ResizeabeTitle
        maxWidth={titleMaxWidth}
        className="max-w-5xl text-4xl font-bold tracking-tighter"
      >
        {title}
      </ResizeabeTitle>
      {Array.isArray(featureImages) && (
        <div
          className={clsx(
            "mt-24 grid w-full grid-cols-1 justify-items-center gap-20",
            {
              "md:grid-cols-2": featureImages.length > 1,
              "lg:grid-cols-3": featureImages.length > 2,
            }
          )}
        >
          {featureImages.map(({ image, cta: imageCta }, index) => (
            <FeatureImage
              key={index}
              image={image}
              cta={imageCta}
              doodles={doodles && !["white", "white-grid"].includes(variation)}
            />
          ))}
        </div>
      )}
      {subtitle && (
        <ResizeabeTitle
          tag="h3"
          maxWidth={subtitleMaxWidth}
          className="mb-5 mt-16 max-w-5xl text-4xl tracking-tighter"
        >
          {subtitle}
        </ResizeabeTitle>
      )}
      {text && (
        <p className="max-w-3xl text-base font-bold tracking-tighter">{text}</p>
      )}
      {get(cta, "url") && <Btn className="mt-14" {...cta} />}
    </div>
  </section>
);

FeatureBlock.displayName = "FeatureBlock";
FeatureBlock.defaultProps = {
  variation: "white",
};

export default FeatureBlock;

export const featureBlockField = {
  label: "Feature",
  name: "featureBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "White Grid BG", value: "white-grid" },
        { label: "Gray", value: "gray" },
        { label: "Gray Grid BG", value: "gray-grid" },
        { label: "Red", value: "red" },
      ],
    },
    {
      label: "Doodles",
      name: "doodles",
      widget: "boolean",
      default: true,
      required: false,
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Images",
      name: "featureImages",
      widget: "list",
      fields: [
        imageField,
        {
          label: "Image Link",
          name: "cta",
          widget: "object",
          fields: get(btnFields, "fields"),
          required: false,
        },
      ],
    },
    {
      label: "Subtitle",
      name: "subtitle",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "subtitleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Text",
      name: "text",
      widget: "string",
      required: false,
    },
    {
      label: "CTA",
      name: "cta",
      widget: "object",
      fields: get(btnFields, "fields"),
      required: false,
    },
  ],
};

export const featureBlockFragment = graphql`
  fragment FeatureBlockContent on featureBlock {
    variation
    featureImages {
      imageUrl
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 400
            aspectRatio: 1
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
          )
        }
      }
      cta {
        ...CTA
      }
    }
    doodles
    title
    titleMaxWidth
    subtitle
    subtitleMaxWidth
    text
    cta {
      ...CTA
    }
  }
`;
