import React from "react";

const VideoIcon = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 90 91"
    className={className}
    {...props}
  >
    <circle
      cx="44.84"
      cy="45.66"
      r="42.24"
      fill="#fff"
      stroke="#333"
      stroke-width="5"
    />
    <path fill="#333" d="M63.66 45.66l-28 16.17V29.5l28 16.16z" />
  </svg>
);

VideoIcon.defaultProps = {
  width: 90,
  height: 91,
};

export default VideoIcon;
