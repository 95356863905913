import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import Image, { imageField } from "app/components/image";
import Btn, { btnFields } from "app/components/btn";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as S from "app/components/modular-content/modular-content.styles";

const themes = {
  black: ["black"],
  gray: ["gray"],
};

const SimpleFeatureBlock = ({
  images,
  title,
  titleMaxWidth,
  cta,
  variation,
  imageUrls,
  ...props
}) => (
  <section css={S.moduleTheme} className={get(themes, variation)}>
    <div className="container my-0 flex w-full flex-col items-center justify-center py-20 text-center">
      {title && (
        <ResizeabeTitle
          maxWidth={titleMaxWidth}
          className="max-w-3xl text-4xl font-bold"
        >
          {title}
        </ResizeabeTitle>
      )}

      {Array.isArray(images) && (
        <div className="mt-24 grid w-full grid-cols-1 justify-items-center gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-32">
          {images.map((image, index) => (
            <div
              key={`image-${index}`}
              className="flex h-52 w-52 transform-gpu items-center justify-center overflow-hidden rounded-full bg-off-white"
            >
              <Image
                className="h-52 w-52 object-cover"
                image={image}
                imageUrl={get(imageUrls, index)}
              />
            </div>
          ))}
        </div>
      )}
      {get(cta, "url") && <Btn className="mt-20" {...cta} />}
    </div>
  </section>
);

SimpleFeatureBlock.displayName = "SimpleFeatureBlock";
SimpleFeatureBlock.defaultProps = {
  variation: "white",
};

export default SimpleFeatureBlock;

export const simpleFeatureBlockField = {
  label: "Simple Feature",
  name: "simpleFeatureBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Gray", value: "gray" },
        { label: "Black", value: "black" },
      ],
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Images",
      name: "images",
      widget: "list",
      field: imageField,
    },
    {
      label: "CTA",
      name: "cta",
      widget: "object",
      fields: get(btnFields, "fields"),
      required: false,
    },
  ],
};

export const simpleFeatureFragment = graphql`
  fragment SimpleFeatureBlockContent on simpleFeatureBlock {
    variation
    imageUrls
    images {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 400
          aspectRatio: 1
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    title
    cta {
      ...CTA
    }
  }
`;
