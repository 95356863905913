import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import { removeEmptyKeys } from "app/utils";

import Btn, { btnFields } from "app/components/btn";
import MarkdownBlock from "app/components/markdown-block";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";
import TransformBox, { transformField } from "app/components/transform-box";

import lines_02 from "app/images/lines_02.png";
import arrow_01_white from "app/images/arrow_01_white.png";
import arrow_03_white from "app/images/doodle-arrow3-mask.png";

import * as S from "app/components/modular-content/modular-content.styles";

const FullCard = ({ caption, cta, text, title }) => (
  <section className="flex w-full max-w-[400px] flex-col bg-off-white p-9 text-center text-gray-dark shadow-card">
    <h2 className="flex min-h-[180px] flex-col">
      <b className="mb-6 block h-[1em] text-base font-bold uppercase">
        {caption}
      </b>
      <span className="flex min-h-[105px] flex-col justify-end px-2 text-[24px] font-bold leading-[35px]">
        {title}
      </span>
    </h2>
    <div className="mx-auto mb-4 w-full max-w-[250px]">
      <img className="-mt-2 w-full" src={lines_02} alt="" />
    </div>
    {text && (
      <div className="mb-6">
        <MarkdownBlock content={text} />
      </div>
    )}
    {get(cta, "url") && <Btn className="mt-auto" {...cta} />}
  </section>
);

const themes = {
  red: ["red gray-button dots-bg"],
  gray: ["gray dots-bg"],
  white: ["white"],
};

const Doodles = ({ variation, doodle1, doodle2 }) => {
  return (
    <div className="relative z-0 mx-auto max-w-5xl overflow-visible">
      <TransformBox
        className="top-0 right-0"
        transform={{
          x: "100%",
          y: "-50px",
          rotate: 0,
          ...removeEmptyKeys(doodle1),
        }}
      >
        <img src={arrow_01_white} width={325} height={425} alt="" />
      </TransformBox>
      <TransformBox
        className="top-0 left-0"
        transform={{
          x: "-100%",
          y: "12px",
          rotate: 90,
          ...removeEmptyKeys(doodle2),
        }}
      >
        {variation !== "red" && (
          <div className="absolute left-5 top-5 h-8 w-8 rounded-full bg-red" />
        )}
        <img
          className="scale-[-1]"
          width={350}
          height={335}
          src={arrow_03_white}
          alt=""
        />
      </TransformBox>
    </div>
  );
};

const titleCardBGs = {
  red: "bg-red",
  black: "bg-gray-dark",
};

const titleCardSVG = {
  red: "#EE7979",
  black: "#474747",
};

const TitleCard = ({ title, variation }) => (
  <p
    className={`relative flex min-h-[560px] w-full max-w-[400px] flex-col items-center justify-center p-5 text-center text-4xl text-white shadow-card ${get(
      titleCardBGs,
      variation
    )}`}
  >
    <span className="relative z-10">{title}</span>
    <svg
      className="absolute left-5 right-5 top-1/2 z-0 -translate-y-1/2"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 325 333"
    >
      <path
        stroke={get(titleCardSVG, variation) || "currentColor"}
        strokeLinecap="round"
        strokeWidth="6"
        d="M104.63 36.4s37.06-62.16 138.5-14.85c28.87 13.47 75 60.97 77.71 112.7 8.45 160.98-131.68 229.2-229.99 179.54C4.65 270.24-29.86 144.95 44.67 54.87c80.4-97.18 238.48-23.58 251.74 75.92 9.96 74.69-33.36 149.6-111.18 166.76C75.13 321.83-8.86 240.5 8.74 164.65"
      ></path>
    </svg>
  </p>
);

const cardStyles = {
  full: <FullCard />,
  titleRed: <TitleCard variation="red" />,
  titleBlack: <TitleCard variation="black" />,
};

const CardsBlock = ({
  doodles,
  doodle1,
  doodle2,
  cards,
  cardStyle,
  intro,
  title,
  titleMaxWidth,
  variation,
}) => {
  const Card = get(cardStyles, cardStyle) || <FullCard />;

  return (
    <section css={S.moduleTheme} className={get(themes, variation)}>
      <div className="my-0 mx-auto w-full max-w-7xl py-20 px-[var(--gutter)]">
        {doodles && (
          <Doodles variation={variation} doodle1={doodle1} doodle2={doodle2} />
        )}
        <div className="relative z-10">
          {title && (
            <ResizeabeTitle
              className="mx-auto mb-8 max-w-5xl text-center text-4xl font-bold tracking-tighter"
              maxWidth={titleMaxWidth}
            >
              {title}
            </ResizeabeTitle>
          )}
          {intro && (
            <div className="mx-auto max-w-5xl text-center">
              <MarkdownBlock content={intro} />
            </div>
          )}
          {Array.isArray(cards) && (
            <div className="mt-24 grid w-full grid-cols-[repeat(auto-fit,_minmax(280px,1fr))] justify-items-center gap-14">
              {cards.map((card, index) =>
                React.cloneElement(Card, { key: index, ...card })
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

CardsBlock.displayName = "CardsBlock";
CardsBlock.defaultProps = {
  variation: "red",
};

export default CardsBlock;

export const cardsBlockField = {
  label: "Cards",
  name: "cardsBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Gray", value: "gray" },
        { label: "Red", value: "red" },
      ],
    },
    {
      label: "Doodles",
      name: "doodles",
      widget: "boolean",
      default: true,
      required: false,
    },
    {
      ...transformField,
      label: "Doodle 1",
      name: "doodle1",
    },
    {
      ...transformField,
      label: "Doodle 2",
      name: "doodle2",
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Intro",
      name: "intro",
      widget: "markdown",
      required: false,
    },
    {
      label: "Card Style",
      name: "cardStyle",
      widget: "select",
      options: [
        { label: "Full Card", value: "full" },
        { label: "Title Card: Red", value: "titleRed" },
        { label: "Title Card: Black", value: "titleBlack" },
      ],
      default: "full",
    },
    {
      label: "Card List",
      name: "cards",
      widget: "list",
      fields: [
        {
          label: "Title",
          name: "title",
          widget: "string",
          required: false,
        },
        {
          label: "Caption",
          name: "caption",
          widget: "string",
          required: false,
        },
        {
          label: "Description",
          name: "text",
          widget: "markdown",
          required: false,
        },
        {
          label: "CTA",
          name: "cta",
          widget: "object",
          fields: get(btnFields, "fields"),
          required: false,
        },
      ],
    },
  ],
};

export const cardsBlockFragment = graphql`
  fragment CardsBlockContent on cardsBlock {
    variation
    doodles
    doodle1 {
      ...TransformBox
    }
    doodle2 {
      ...TransformBox
    }
    title
    titleMaxWidth
    intro
    cardStyle
    cards {
      title
      caption
      text
      cta {
        ...CTA
      }
    }
  }
`;
