// import React from "react";
import _get from "lodash.get";
import { graphql } from "gatsby";
import { MarkdownBlockContainer, markdownBlockField } from "../markdown-block";

export default MarkdownBlockContainer;

export const markdownContentBlock = {
  ...markdownBlockField,
  name: "markdownContentBlock",
  fields: [
    {
      label: "Align Text",
      name: "textAlign",
      widget: "select",
      options: ["left", "center", "right"],
      default: "left",
    },
    ..._get(markdownBlockField, "fields"),
  ],
};

export const markdownContentFragment = graphql`
  fragment MarkdownContent on markdownContentBlock {
    content
    textAlign
    type
  }
`;
