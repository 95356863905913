import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import { removeEmptyKeys } from "app/utils";

import { btnFields } from "app/components/btn";
import Carousel from "app/components/carousel";
import { Quote } from "app/components/modular-content/block-quote";
import TransformBox, { transformField } from "app/components/transform-box";

import arrow_01_white from "app/images/arrow_01_white.png";
import arrow_03_white from "app/images/arrow_03_white.png";
import arrow_01_dark from "app/images/arrow_01_dark.png";
import arrow_03_dark from "app/images/arrow_03_dark.png";

import * as S from "app/components/modular-content/modular-content.styles";

const themes = {
  white: "white dots-bg gray-button",
  red: ["red dots-bg gray-button"],
  gray: ["gray dots-bg gray-button"],
};

const doodleTheme = {
  white: "dark",
  red: "light",
  gray: "light",
};

const Doodles = ({ doodle1, doodle2, variation }) => {
  const dark = doodleTheme[variation] !== "light";

  return (
    <div className="pointer-events-none relative z-0 mx-auto w-full max-w-5xl overflow-visible">
      <TransformBox
        className="bottom-0 left-0"
        transform={{
          x: "-85%",
          y: "-65px",
          rotate: 0,
          ...removeEmptyKeys(doodle1),
        }}
      >
        {dark ? (
          <img
            className="w-[329px] -scale-y-100 transform"
            style={{
              "--tw-translate-x": "-60px",
              "--tw-translate-y": "10px",
              "--tw-rotate": "105deg",
            }}
            src={arrow_01_dark}
            alt=""
          />
        ) : (
          <img className="w-[329px] scale-[-1]" src={arrow_01_white} alt="" />
        )}
      </TransformBox>
      <TransformBox
        className="bottom-0 right-0"
        transform={{ x: "85%", y: 0, rotate: 0, ...removeEmptyKeys(doodle2) }}
      >
        {dark ? (
          <img className="w-[349px]" src={arrow_03_dark} alt="" />
        ) : (
          <img className="w-[349px]" src={arrow_03_white} alt="" />
        )}
      </TransformBox>
    </div>
  );
};

const CarouselQuoteBlock = ({
  doodles,
  doodle1,
  doodle2,
  quotes,
  variation,
}) => (
  <div css={S.moduleTheme} className={`pb-20 pt-40 ${get(themes, variation)}`}>
    <div className="container">
      {Array.isArray(quotes) && (
        <Carousel items={quotes} Component={<Quote invert />} />
      )}
      {doodles && (
        <Doodles variation={variation} doodle1={doodle1} doodle2={doodle2} />
      )}
    </div>
  </div>
);

CarouselQuoteBlock.displayName = "CarouselQuoteBlock";

export default CarouselQuoteBlock;

export const quoteCarouselField = {
  label: "Carousel: Quote",
  name: "quoteCarousel",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Gray", value: "gray" },
        { label: "Red", value: "red" },
      ],
    },
    {
      label: "Doodles",
      name: "doodles",
      widget: "boolean",
      default: true,
      required: false,
    },
    {
      ...transformField,
      label: "Doodle 1",
      name: "doodle1",
    },
    {
      ...transformField,
      label: "Doodle 2",
      name: "doodle2",
    },
    {
      label: "Quotes",
      name: "quotes",
      widget: "list",
      fields: [
        {
          label: "Caption",
          name: "caption",
          widget: "string",
          required: false,
        },
        {
          label: "Quote",
          name: "quote",
          widget: "string",
        },
        {
          label: "CTA",
          name: "cta",
          widget: "object",
          fields: get(btnFields, "fields"),
          required: false,
        },
      ],
    },
  ],
};

export const quoteCarouselFragment = graphql`
  fragment CarouselQuote on quoteCarousel {
    type
    variation
    doodles
    doodle1 {
      ...TransformBox
    }
    doodle2 {
      ...TransformBox
    }
    title
    titleMaxWidth
    quotes {
      quote
      caption
      cta {
        ...CTA
      }
    }
  }
`;
