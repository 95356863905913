import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import Image, { imageField } from "../image";
import Carousel from "app/components/carousel";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as S from "app/components/modular-content/modular-content.styles";

const TextSlide = ({ text }) => (
  <p css={[S.textXl, S.maxWidth800px, S.mxauto]}>{text}</p>
);

const CarouselText = ({ slides }) => {
  if (!Array.isArray(slides) || !slides.length) {
    return null;
  }

  return (
    <Carousel
      items={slides.map((text) => ({ text }))}
      Component={<TextSlide />}
    />
  );
};

const ImageSlide = ({ image, imageUrl }) => (
  <Image image={image} imageUrl={imageUrl} objectPosition={"50% 50%"} />
);

const CarouselImage = ({ images, imagesUrls }) => {
  if (!Array.isArray(images) || !images.length) {
    return null;
  }
  return (
    <Carousel
      items={images.map((image, index) => ({
        image,
        imageUrl: get(imagesUrls, index),
      }))}
      Component={<ImageSlide />}
    />
  );
};

const CarouselTypes = {
  imageCarousel: CarouselImage,
  textCarousel: CarouselText,
};

const CarouselBlock = ({ type, first, title, titleMaxWidth, ...props }) => {
  const CarouselComponent = get(CarouselTypes, type) || React.Fragment;

  return (
    <div css={[S.container, S.txtCenter, S.py20, !first && S.borderT]}>
      {title && (
        <ResizeabeTitle
          maxWidth={titleMaxWidth}
          css={[S.textBase, S.bold, S.uppercase, S.mb16]}
        >
          {title}
        </ResizeabeTitle>
      )}
      <CarouselComponent {...props} />
    </div>
  );
};

export default CarouselBlock;

export const imageCarouselBlock = {
  label: "Carousel: Image",
  name: "imageCarousel",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Slides",
      name: "images",
      widget: "list",
      field: imageField,
    },
  ],
};

export const imageCarouselFragment = graphql`
  fragment CarouselImage on imageCarousel {
    type
    title
    titleMaxWidth
    imagesUrls
    images {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 1200
          # placeholder: TRACED_SVG
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;

export const textCarouselBlock = {
  label: "Carousel: Text",
  name: "textCarousel",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Slides",
      name: "slides",
      widget: "list",
      field: {
        label: "Text",
        name: "text",
        widget: "text",
      },
    },
  ],
};

export const textCarouselFragment = graphql`
  fragment CarouselText on textCarousel {
    type
    title
    titleMaxWidth
    slides
  }
`;
