import React from "react";
import clsx from "clsx";

const ResizeabeTitle = ({
  tag: Tag = "h2",
  maxWidth,
  style,
  title,
  className,
  center = true,
  ...props
}) => (
  <Tag
    className={clsx([className, !!maxWidth && center !== false && "mx-auto"])}
    style={!!maxWidth ? { ...style, maxWidth } : style}
    {...props}
  />
);

export const resizeableTitleFields = [
  {
    label: "Title",
    name: "title",
    widget: "string",
    required: false,
  },
  {
    label: "Title Max Width",
    name: "titleMaxWidth",
    widget: "number",
    value_type: "int",
    required: false,
  },
];

export default ResizeabeTitle;
