import React from "react";
import { graphql } from "gatsby";
// import _get from "lodash.get";

import Image, { imageField } from "../image";
import MarkdownBlock from "../markdown-block";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as S from "./modular-content.styles";

const SplitImageHero = ({
  imageAlign,
  title,
  titleMaxWidth,
  subTitle,
  subtitleMaxWidth,
  image,
  imageUrl,
  content,
}) => (
  <div
    css={[
      S.flex,
      S.flexCol,
      imageAlign === "right" ? S.flexRowReverseMd : S.flexRowMd,
      S.itemsCenter,
      S.bgGray,
    ]}
  >
    <div css={[S.relative, S.splitImage]}>
      <Image image={image} imageUrl={imageUrl} css={[S.w100, S.cover]} />
    </div>
    <div
      css={[
        S.w1of2Md,
        S.flex,
        S.flexCol,
        S.justifyCenter,
        imageAlign === "right" ? S.itemsEnd : S.itemsStart,
      ]}
    >
      <div
        css={[
          S.flex,
          S.flexCol,
          S.itemsCenter,
          S.justifyCenter,
          S.splitContent,
        ]}
      >
        <div css={S.maxWidth400px}>
          <ResizeabeTitle
            maxWidth={titleMaxWidth}
            center={false}
            css={[S.textXl, S.mb4]}
          >
            {subTitle && (
              <ResizeabeTitle
                tag="small"
                center={false}
                maxWidth={subtitleMaxWidth}
                css={[S.block, S.textBase, S.bold, S.mb1, S.red, S.uppercase]}
              >
                {subTitle}
              </ResizeabeTitle>
            )}
            {title}
          </ResizeabeTitle>
          <MarkdownBlock content={content} />
        </div>
      </div>
    </div>
  </div>
);

export default SplitImageHero;

export const splitImageHeroBlock = {
  label: "Split Image Hero",
  name: "splitImageHeroBlock",
  widget: "object",
  fields: [
    {
      label: "Image Alignment",
      name: "imageAlign",
      widget: "select",
      options: ["left", "right"],
      default: "left",
    },
    imageField,
    {
      label: "Subtitle",
      name: "subTitle",
      widget: "string",
      required: false,
    },
    {
      label: "Subtitle Max Width",
      name: "subtitleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Content",
      name: "content",
      widget: "markdown",
    },
  ],
};

export const splitImageHeroFragment = graphql`
  fragment SplitImageHero on splitImageHeroBlock {
    imageAlign
    title
    titleMaxWidth
    subTitle
    subtitleMaxWidth
    imageUrl
    image {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          # placeholder: TRACED_SVG
          formats: [AUTO, WEBP]
        )
      }
    }
    content
  }
`;
