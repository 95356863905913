import { css } from "@emotion/react";

export * from "../../styles/shared";

export const maxWidth112 = css`
  --max-width: 28rem;
`;

export const overlay = css`
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;
