import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import Image, { imageField } from "app/components/image";
import Btn, { btnFields } from "app/components/btn";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as S from "app/components/modular-content/modular-content.styles";

const themes = {
  red: ["red gray-button"],
  gray: ["gray "],
};

const SingleStatBlock = ({
  image,
  imageUrl,
  title,
  titleMaxWidth,
  subtitle,
  subtitleMaxWidth,
  cta,
  variation,
}) => (
  <section css={S.moduleTheme} className={get(themes, variation)}>
    <div className="container my-0 flex w-full flex-col items-center justify-center py-20 text-center">
      <div className="mb-5 flex h-52 w-52 transform-gpu items-center justify-center overflow-hidden rounded-full bg-off-white">
        <Image
          className="h-52 w-52"
          image={image}
          imageUrl={imageUrl}
          alt={title}
          src={get(image, "publicURL")}
        />
      </div>
      {title && (
        <ResizeabeTitle maxWidth={titleMaxWidth} className="text-4xl font-bold">
          {title}
        </ResizeabeTitle>
      )}
      {subtitle && (
        <ResizeabeTitle
          tag="p"
          maxWidth={subtitleMaxWidth}
          className="mt-2 text-xl"
        >
          {subtitle}
        </ResizeabeTitle>
      )}
      {get(cta, "url") && <Btn className="mt-10" {...cta} />}
    </div>
  </section>
);

SingleStatBlock.displayName = "SingleStatBlock";
SingleStatBlock.defaultProps = {
  variation: "white",
};

export default SingleStatBlock;

export const singleStatBlockField = {
  label: "Single Stat",
  name: "singleStatBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Gray", value: "gray" },
        { label: "Red", value: "red" },
      ],
    },
    imageField,
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Subtitle",
      name: "subtitle",
      widget: "string",
      required: false,
    },
    {
      label: "Subtitle Max Width",
      name: "subtitleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "CTA",
      name: "cta",
      widget: "object",
      fields: get(btnFields, "fields"),
      required: false,
    },
  ],
};

export const singleStatFragment = graphql`
  fragment SingleStatBlockContent on singleStatBlock {
    variation
    imageUrl
    image {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 400
          aspectRatio: 1
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    title
    titleMaxWidth
    subtitle
    subtitleMaxWidth
    cta {
      ...CTA
    }
  }
`;
