import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import Btn, { btnFields } from "../btn";
import Image from "../image";
import MarkdownBlock from "../markdown-block";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as SHero from "./block-hero.styles";
import * as SModular from "app/components/modular-content/modular-content.styles";

const S = {
  ...SHero,
  ...SModular,
};

const themes = {
  red: ["red gray-button"],
  gray: ["gray dots-bg"],
  white: ["white"],
};

const HeroBlock = ({
  title,
  titleMaxWidth,
  content,
  image,
  imageUrl,
  cta,
  isNarrow,
  isFirst,
  variation,
}) => {
  const hasImage = image || imageUrl;

  return (
    <div
      css={S.moduleTheme}
      className={`relative pb-20 ${hasImage ? "pt-40" : "pt-20"} ${get(
        themes,
        variation
      )}`}
    >
      {hasImage && (
        <div className="absolute inset-0">
          <div className="absolute inset-0 z-10 h-full w-full bg-black opacity-5 bg-blend-multiply" />
          <Image
            image={image}
            imageUrl={imageUrl}
            className="relative z-0 h-full w-full object-cover"
          />
        </div>
      )}
      <div
        className={`container relative z-10 flex flex-col items-center justify-center text-center ${
          hasImage ? "text-white" : ""
        }`}
        css={[S.containerNarrower, isNarrow && S.maxWidth112]}
      >
        {isFirst && (
          <ResizeabeTitle
            tag="h1"
            maxWidth={titleMaxWidth}
            className="mb-5 text-4xl font-bold tracking-tighter"
          >
            {title}
          </ResizeabeTitle>
        )}
        {!isFirst && (
          <ResizeabeTitle
            tag="h2"
            maxWidth={titleMaxWidth}
            className="mb-5 text-4xl font-bold tracking-tighter"
          >
            {title}
          </ResizeabeTitle>
        )}
        <MarkdownBlock content={content} />
        {get(cta, "url") && <Btn className="mt-4" {...cta} />}
      </div>
    </div>
  );
};

export default HeroBlock;

export const heroBlockField = {
  label: "Hero",
  name: "heroBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Gray", value: "gray" },
        { label: "Red", value: "red" },
      ],
    },
    {
      label: "Background Image",
      name: "image",
      widget: "image",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Content",
      name: "content",
      widget: "markdown",
      required: false,
    },
    {
      label: "CTA",
      name: "cta",
      widget: "object",
      fields: get(btnFields, "fields"),
      required: false,
    },
  ],
};

export const heroBlockFragment = graphql`
  fragment HeroBlockContent on heroBlock {
    variation
    imageUrl
    image {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    title
    titleMaxWidth
    content
    cta {
      label
      url
      newWindow
    }
  }
`;
