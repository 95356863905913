import React from "react";
import { graphql } from "gatsby";
import clsx from "clsx";

const safeRotate = (rotate) => {
  const deg = parseInt(rotate, 10);
  return isNaN(deg) ? 0 : deg;
};

const TransformBox = ({ className, transform, children }) => (
  <div
    className={clsx("absolute origin-center transform", className)}
    style={{
      "--tw-translate-x": transform.x,
      "--tw-translate-y": transform.y,
      "--tw-rotate": `${safeRotate(transform.rotate)}deg`,
    }}
    role="presentation"
    aria-hidden="true"
  >
    {children}
  </div>
);

export default TransformBox;

export const transformField = {
  label: "Transform",
  name: "transform",
  widget: "object",
  fields: [
    {
      label: "X Offset",
      name: "x",
      widget: "string",
      required: false,
    },
    {
      label: "Y Offset",
      name: "y",
      widget: "string",
      required: false,
    },
    {
      label: "Rotation",
      name: "rotate",
      widget: "number",
      value_type: "int",
      required: false,
    },
  ],
};

export const transformFragment = graphql`
  fragment TransformBox on Transform {
    x
    y
    rotate
  }
`;
