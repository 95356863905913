import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import AnchorLink from "../anchor-link";
import CardsBlock, { cardsBlockField } from "./block-cards";
import CarouselBlock, {
  imageCarouselBlock,
  textCarouselBlock,
} from "./block-carousel";
import CarouselQuoteBlock, { quoteCarouselField } from "./block-carousel-quote";
import GridBlock, { gridBlockField } from "./block-grid";
import HeroBlock, { heroBlockField } from "./block-hero";
import HeroMastBlock, { heroMastBlockField } from "./block-hero-mast";
import ImageBlock, { imageBlockField } from "./block-image";
import FeatureBlock, { featureBlockField } from "./block-feature";
import FeaturedImage, { featuredImageBlock } from "./block-featured-image";
import MarkdownContent, {
  markdownContentBlock,
} from "./block-markdown-content";
import MarkdownWithImage, {
  markdownWithImageBlock,
} from "./block-markdown-with-image";
import PardotFormBlock, { pardotBlockField } from "./block-pardot-form";
import SplitImageHero, { splitImageHeroBlock } from "./block-split-image-hero";
import SingleStatBlock, { singleStatBlockField } from "./block-single-stat";
import SimpleFeatureBlock, {
  simpleFeatureBlockField,
} from "./block-simple-feature";
import StatsBlock, { statsBlockField } from "./block-stats";
import QuoteBlock, { quoteBlockField } from "./block-quote";

import * as S from "./modular-content.styles";

const blockTypes = {
  cardsBlock: CardsBlock,
  gridBlock: GridBlock,
  heroBlock: HeroBlock,
  heroMastBlock: HeroMastBlock,
  imageBlock: ImageBlock,
  markdownWithImageBlock: MarkdownWithImage,
  markdownContentBlock: MarkdownContent,
  pardotFormBlock: PardotFormBlock,
  imageCarousel: CarouselBlock,
  textCarousel: CarouselBlock,
  quoteCarousel: CarouselQuoteBlock,
  featureBlock: FeatureBlock,
  featuredImage: FeaturedImage,
  quoteBlock: QuoteBlock,
  singleStatBlock: SingleStatBlock,
  simpleFeatureBlock: SimpleFeatureBlock,
  statsBlock: StatsBlock,
  splitImageHeroBlock: SplitImageHero,
};

const ContentBlock = ({ type, htmlId, ...props }, idx) => {
  const Component = _get(blockTypes, type);

  return !Component ? null : (
    <React.Fragment key={`${type}-${idx}`}>
      <AnchorLink name={htmlId || `section-${idx + 1}`} tabIndex="0">
        Anchor Link to Section {idx}
      </AnchorLink>
      <Component {...props} type={type} />
    </React.Fragment>
  );
};

const ModularContent = ({ modularContent }) => {
  if (!Array.isArray(modularContent) || !modularContent.length) {
    return null;
  }

  return <div css={S.maxWidth}>{modularContent.map(ContentBlock)}</div>;
};

export default ModularContent;

export const modularContentField = {
  label: "Modular Content",
  name: "modularContent",
  widget: "list",
  types: [
    cardsBlockField,
    gridBlockField,
    heroBlockField,
    heroMastBlockField,
    imageBlockField,
    imageCarouselBlock,
    textCarouselBlock,
    quoteCarouselField,
    featureBlockField,
    featuredImageBlock,
    markdownContentBlock,
    markdownWithImageBlock,
    pardotBlockField,
    singleStatBlockField,
    simpleFeatureBlockField,
    splitImageHeroBlock,
    statsBlockField,
    quoteBlockField,
  ].map(({ fields, ...blockTypeFields }) => ({
    ...blockTypeFields,
    fields: [
      {
        label: "Anchor Link ID",
        name: "htmlId",
        widget: "string",
        required: false,
        hint: "This must be a valid url hash. If left blank, 'section-{number}' will be used.",
      },
      ...fields,
    ],
  })),
};

export const modularContentFragment = graphql`
  fragment ModularBlock on ModularBlockJson {
    type
    htmlId
    ...CardsBlockContent
    ...CarouselImage
    ...CarouselText
    ...CarouselQuote
    ...FeatureBlockContent
    ...FeaturedImage
    ...GridBlockContent
    ...HeroBlockContent
    ...HeroMastBlockContent
    ...ImageBlockContent
    ...MarkdownContent
    ...MarkdownWithImage
    ...PardotFormBlockContent
    ...QuoteBlockContent
    ...SimpleFeatureBlockContent
    ...SingleStatBlockContent
    ...StatsBlockContent
    ...SplitImageHero
  }
`;
