import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import get from "lodash.get";

import Btn, { btnFields } from "app/components/btn";

import * as S from "app/components/modular-content/block-quote.styles";

const themes = {
  red: ["red gray-button"],
  gray: ["gray grid-bg"],
};

export const Quote = ({ quote, caption, cta, invert }) => (
  <div css={[S.txtCenter, S.maxWidth950px, S.mxauto]}>
    <div className={`flex ${invert ? "flex-col-reverse" : "flex-col"}`}>
      <blockquote>
        <p className={`${invert ? "mt-4" : "mb-4"} text-4xl tracking-tighter`}>
          {quote}
        </p>
      </blockquote>
      {caption && (
        <figcaption className={"text-base font-bold uppercase"}>
          {caption}
        </figcaption>
      )}
    </div>
    {get(cta, "url", false) && <Btn css={[S.mt10]} {...cta} />}
  </div>
);

Quote.displayName = "Quote";

const QuoteBlock = ({ variation, ...props }) => (
  <figure
    css={S.moduleTheme}
    className={clsx("m-0 py-20", get(themes, variation))}
  >
    <Quote {...props} />
  </figure>
);

QuoteBlock.displayName = "QuoteBlock";
QuoteBlock.defaultProps = {
  variation: "white",
};

export default QuoteBlock;

export const quoteBlockField = {
  label: "Quote",
  name: "quoteBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Gray", value: "gray" },
        { label: "Red", value: "red" },
      ],
    },
    {
      label: "Quote",
      name: "quote",
      widget: "string",
    },
    {
      label: "Caption",
      name: "caption",
      widget: "string",
      required: false,
    },
    {
      label: "CTA",
      name: "cta",
      widget: "object",
      fields: get(btnFields, "fields"),
      required: false,
    },
  ],
};

export const quoteBlockFragment = graphql`
  fragment QuoteBlockContent on quoteBlock {
    quote
    caption
    variation
    cta {
      ...CTA
    }
  }
`;
