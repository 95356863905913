import React from "react";
import { graphql } from "gatsby";

import Image, { imageField } from "app/components/image";
import PlayIcon from "app/components/icons/play-icon";
import Link from "app/components/link";

import { parseVideoId } from "app/hooks/use-video-modal";

import * as S from "app/components/modular-content/block-image.styles";

const ImageBlock = ({ image, imageUrl, videoUrl }) => {
  const videoId = parseVideoId(videoUrl);
  return (
    <Link href={videoUrl} css={S.imageBlock} optional>
      <Image image={image} imageUrl={imageUrl} css={S.image} />
      {!!videoId && <PlayIcon css={S.playIcon} />}
    </Link>
  );
};

ImageBlock.displayName = "ImageBlock";

export default ImageBlock;

export const imageBlockField = {
  label: "Image",
  name: "imageBlock",
  widget: "object",
  fields: [
    imageField,
    {
      label: "Video Url",
      name: "videoUrl",
      widget: "string",
      required: false,
    },
  ],
};

export const imageBlockFragment = graphql`
  fragment ImageBlockContent on imageBlock {
    videoUrl
    image {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          # placeholder: TRACED_SVG
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;
