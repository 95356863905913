import { css } from "@emotion/react";

export { textBase, bold, uppercase } from "app/styles/text-styles";
export * from "app/styles/shared";

export const grid = css`
  --grid-bg: var(--gray-dark);

  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 940px) {
    grid-template-columns: 10fr 10fr 12fr;
  }

  gap: 5px;
  width: 100%;
  justify-items: stretch;
  align-items: stretch;
  color: white;
`;

export const gridSmall = css`
  @media (min-width: 940px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const grid1Col = css`
  @media (min-width: 650px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 940px) {
    grid-template-columns: 1fr;
  }
`;

const gridItem1 = css`
  @media (min-width: 650px) {
    grid-column: 1 / span 2;
  }

  @media (min-width: 940px) {
    grid-row: 1 / 1;
  }
`;

const gridItem2 = css`
  @media (min-width: 940px) {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
  }
`;

const gridItem3 = css`
  @media (min-width: 940px) {
    grid-row: 2 / 2;
    grid-column: 2 / 2;
  }
`;

const gridItem4 = css`
  @media (min-width: 650px) {
    grid-column: 1 / span 2;
  }

  @media (min-width: 940px) {
    grid-row: 1 / 3;
    grid-column: 3 / 3;
  }
`;

const gridItem5 = css`
  @media (min-width: 940px) {
    grid-row: 3 / 3;
    grid-column: 1 / 3;
  }
`;

const gridItem6 = css`
  @media (min-width: 940px) {
    grid-row: 3 / 3;
    grid-column: 3 / 3;
  }
`;

export const items = [
  gridItem1,
  gridItem2,
  gridItem3,
  gridItem4,
  gridItem5,
  gridItem6,
];

const grid2ColItem1 = css`
  grid-column: 1 / span 2;

  @media (min-width: 650px) {
    grid-column: 1 / 1;
    grid-row: 1;
  }
`;

const grid2ColItem2 = css`
  grid-column: 1 / span 2;
  grid-row: 2;

  @media (min-width: 650px) {
    grid-column: 2;
    grid-row: 1;
  }
`;

export const items2Col = [grid2ColItem1, grid2ColItem2];

export const gridItem = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px 30px;
  min-height: 240px;
  text-align: left;
  background: var(--grid-bg);
  color: white;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }

  @media (min-width: 650px) {
    min-height: 330px;
  }
`;

export const bgImage = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: top center;
  object-fit: cover;
  opacity: 0.4;
`;
