import React from "react";
import { graphql } from "gatsby";
// import _get from "lodash.get";

import Image, { imageField } from "../image";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";
import VideoIcon from "../icons/video-icon";

import useVideoModal from "../../hooks/use-video-modal";

import * as S from "./modular-content.styles";

const Video = ({ videoUrl, image, imageUrl }) => {
  const { openModal } = useVideoModal();

  return (
    <button onClick={() => openModal(videoUrl)} css={S.relative}>
      <Image image={image} imageUrl={imageUrl} />
      <VideoIcon css={[S.absolute, S.top1of2, S.left1of2, S.transformCenter]} />
    </button>
  );
};

const FeaturedImage = ({
  first,
  title,
  titleMaxWidth,
  videoUrl,
  image,
  imageUrl,
}) => (
  <div css={[S.container, S.txtCenter, S.py20, !first && S.borderT]}>
    <ResizeabeTitle
      maxWidth={titleMaxWidth}
      css={[S.textBase, S.bold, S.uppercase, S.mb16]}
    >
      {title}
    </ResizeabeTitle>
    {!videoUrl && <Image image={image} imageUrl={imageUrl} />}
    {videoUrl && (
      <Video image={image} imageUrl={imageUrl} videoUrl={videoUrl} />
    )}
  </div>
);

export default FeaturedImage;

export const featuredImageBlock = {
  label: "Featured Image",
  name: "featuredImage",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    imageField,
    {
      label: "Video Url",
      name: "videoUrl",
      widget: "string",
      required: false,
    },
  ],
};

export const featuredImageFragment = graphql`
  fragment FeaturedImage on featuredImage {
    title
    titleMaxWidth
    imageUrl
    image {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          # placeholder: TRACED_SVG
          formats: [AUTO, WEBP]
        )
      }
    }
    videoUrl
  }
`;
