import React, { memo } from "react";
import SVGIcon from "./svg-icon";

const DoodleCircle = (props) => (
  <SVGIcon {...props}>
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="6"
      d="M97.7 33.6s34.8-58.3 130-14c27.1 12.7 70.4 57.3 73 105.9C308.6 276.6 177 340.7 84.7 294 3.8 253.2-28.6 135.5 41.4 51c75.5-91.3 223.9-22.2 236.3 71.2 9.4 70.2-31.3 140.5-104.4 156.6C70 301.6-8.9 225.2 7.6 154"
    />
  </SVGIcon>
);

DoodleCircle.defaultProps = {
  viewBox: "0 0 304 312",
  width: "304",
  height: "312",
};

export default memo(DoodleCircle);
