import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
// import _get from "lodash.get";

import Image, { imageField } from "../image";

import MarkdownBlock from "../markdown-block";

import * as S from "./modular-content.styles";
import get from "lodash.get";

const textAligments = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

const MarkdownWithImage = ({
  first,
  imageAlign,
  textAlign,
  image,
  imageUrl,
  content,
}) => (
  <div
    className={clsx(
      "container flex gap-20",
      imageAlign === "left"
        ? "flex-col md:flex-row"
        : "flex-col-reverse md:flex-row-reverse"
    )}
    css={[S.py20, !first && S.borderT]}
  >
    <div className={"flex flex-col items-center justify-center md:w-fit"}>
      <div
        css={[S.squareImg]}
        className="aspect-square overflow-hidden rounded-full"
      >
        <Image
          className="aspect-square h-full w-full object-cover"
          image={image}
          imageUrl={imageUrl}
        />
      </div>
    </div>

    <div
      className={clsx(
        "flex w-full flex-col justify-center",
        get(textAligments, textAlign)
      )}
    >
      <MarkdownBlock content={content} />
    </div>
  </div>
);

export default MarkdownWithImage;

export const markdownWithImageBlock = {
  label: "Content with Image",
  name: "markdownWithImageBlock",
  widget: "object",
  fields: [
    {
      label: "Image Alignment",
      name: "imageAlign",
      widget: "select",
      options: ["left", "right"],
      default: "left",
    },
    imageField,
    {
      label: "Text Alignment",
      name: "textAlign",
      widget: "select",
      options: ["left", "center", "right"],
      default: "left",
    },
    {
      label: "Content",
      name: "content",
      widget: "markdown",
    },
  ],
};

export const markdownWithImageFragment = graphql`
  fragment MarkdownWithImage on markdownWithImageBlock {
    imageAlign
    imageUrl
    image {
      childImageSharp {
        gatsbyImageData(
          width: 400
          layout: CONSTRAINED
          aspectRatio: 1
          # placeholder: TRACED_SVG
          formats: [AUTO, WEBP]
        )
      }
    }
    content
    textAlign
  }
`;
