import { css } from "@emotion/react";

export const imageBlock = css`
  position: relative;
  display: block;
  width: 100%;
  height: 675px;
  border: 0;
`;

export const image = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const playIcon = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 80px;
  height: 80px;
  color: var(--gray-dark);
  z-index: 2;
`;
