import React from "react";
import SVGIcon from "./svg-icon";

const PlayIcon = ({ bg, color, ...props }) => (
  <SVGIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="25" cy="25" r="25" fill={bg} />
      <path fill={color} d="M35 25l-15 8.3V16.7z" />
    </g>
  </SVGIcon>
);

PlayIcon.defaultProps = {
  viewBox: "0 0 50 50",
  bg: "#fff",
  color: "currentColor",
  width: "50",
  height: "50",
};

export default PlayIcon;
