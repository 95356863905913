import { css } from "@emotion/react";

export * from "../../styles/shared";

export const maxWidth = css`
  --max-width: calc(1024px + var(--gutter) * 2);
`;

export const squareImg = css`
  width: 310px;

  @media (max-width: 600px) {
    margin-top: 80px;
  }

  @media (max-width: 400px) {
    max-width: 100%;
  }
`;

export const borderT = css`
  border-top: 1px solid var(--border-color);
`;

export const transformCenter = css`
  transform: translate3d(-50%, -50%, 0);
`;

export const splitContent = css`
  padding: 120px var(--gutter) 80px;

  @media (min-width: 600px) {
    padding: 4rem;
    width: 100%;
    max-width: calc((var(--max-width) / 2) + var(--gutter));
  }
`;

export const bgImage = css`
  @media (min-width: 600px) {
    min-height: 600px;
  }
`;

export const splitImage = css`
  width: 100%;
  min-height: 340px;

  @media (min-width: 600px) {
    width: 50%;
    min-height: 600px;
  }
`;

export const maxWidth400px = css`
  max-width: 400px;
`;

export const bgGray = css`
  background-color: #f2f2f2;
`;

export const moduleTheme = css`
  --module-text: var(--gray-dark);
  --module-bg-color: transparent;
  --module-bg-image: none;

  --button-border-color: var(--red);

  --grid-color: #d9d9d9;
  --dots-color: #d9d9d9;

  --image-bg: var(--off-white);

  color: var(--module-text);
  background-color: var(--module-bg-color);

  &.red {
    --module-bg-color: var(--red);
    --module-text: white;

    --link: white;
    --link-hover: white;
    --link-border: white;

    --image-bg: var(--white);

    --pager-active: var(--white);
    --pager-border-active: var(--white);

    --pager-inactive: var(--red);
    --pager-border-inactive: var(--white);
  }

  &.black {
    --module-bg-color: var(--gray-dark);
    --module-text: white;

    --image-bg: var(--white);

    --pager-inactive: var(--white);
    --pager-border-inactive: var(--white);
  }

  &.gray {
    --module-bg-color: var(--gray-bg-light);
    --module-text: var(--gray-dark);
    --grid-color: #f0f0f0;
    --dots-color: #838181;

    --image-bg: var(--white);

    --pager-inactive: var(--white);
    --pager-border-inactive: var(--white);
  }

  &.gray-button {
    --button-border-color: var(--gray-dark);
    --button-bg: var(--gray-dark);
    --button-text: var(--white);
  }

  &.grid-bg {
    background-size: 40px 40px;
    /* prettier-ignore */
    background-image: 
      linear-gradient(to right, var(--grid-color) 1px, transparent 1px),
      linear-gradient(to bottom, var(--grid-color) 1px, transparent 1px);
  }

  &.dots-bg {
    /* prettier-ignore */
    background-image: 
      radial-gradient(var(--dots-color) 1px, transparent 1px),
      radial-gradient(var(--dots-color) 1px, transparent 1px);
    background-position: 0 0, 30px 30px;
    background-size: 60px 60px;
  }
`;
