import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import { removeEmptyKeys } from "app/utils";

import Btn, { btnFields } from "app/components/btn";
import Image, { imageField } from "app/components/image";
import MarkdownBlock from "app/components/markdown-block";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";
import TransformBox, { transformField } from "app/components/transform-box";

import arrow_01_dark from "app/images/arrow_01_dark.png";
import arrow_02_dark from "app/images/arrow_02_dark.png";

import * as ModularStyles from "app/components/modular-content/modular-content.styles";
import * as Styles from "app/styles/shared";

const S = {
  ...ModularStyles,
  ...Styles,
};

const themes = {
  white: ["white"],
  whiteGrid: ["white grid-bg"],
  red: ["red gray-button"],
  gray: ["gray"],
  grayGrid: ["gray grid-bg"],
};

const Doodles = ({ doodle1, doodle2 }) => (
  <>
    <TransformBox
      className="top-0 right-0"
      transform={{
        x: "190px",
        y: "70px",
        rotate: 80,
        ...removeEmptyKeys(doodle1),
      }}
    >
      <img className="h-[305px] w-[325px]" src={arrow_02_dark} alt="" />
      <div className="absolute -left-3 bottom-4 h-8 w-8 rounded-full bg-red" />
    </TransformBox>
    <TransformBox
      className="bottom-0 left-0"
      transform={{
        x: "-90%",
        y: "-33.33%",
        rotate: -30,
        ...removeEmptyKeys(doodle2),
      }}
    >
      <img className="h-[406px] w-[277px]" src={arrow_01_dark} alt="" />
      <div className="absolute -bottom-5 right-9 h-8 w-8 rounded-full bg-red" />
    </TransformBox>
  </>
);

const imageStyles = {
  solid: {
    backgroundColor: "var(--image-bg)",
  },
};

const StatItem = ({ image, imageUrl, imageStyle, content, statText }) => (
  <figure className="flex max-w-[200px] flex-col items-center justify-start text-center">
    {(image || imageUrl) && (
      <div
        className={"mb-6 h-[200px] w-[200px] overflow-hidden rounded-full"}
        style={get(imageStyles, imageStyle)}
      >
        <Image image={image} imageUrl={imageUrl} className="object-cover" />
      </div>
    )}
    {statText && (
      <h2 className="mb-4 text-[64px] font-bold leading-[1.2]">{statText}</h2>
    )}
    <MarkdownBlock content={content} />
  </figure>
);

const StatsBlock = ({
  doodles,
  doodle1,
  doodle2,
  stats,
  imageStyle,
  title,
  titleMaxWidth,
  intro,
  variation,
  cta,
}) => {
  return (
    <section css={S.moduleTheme} className={get(themes, variation)}>
      <div className="relative my-0 mx-auto w-full max-w-7xl py-20 px-[var(--gutter)]">
        {!!doodles && variation !== "red" && (
          <Doodles doodle1={doodle1} doodle2={doodle2} />
        )}
        <div className="relative z-10">
          {title && (
            <ResizeabeTitle
              maxWidth={titleMaxWidth}
              className="mx-auto mb-8 max-w-5xl text-center text-4xl font-bold tracking-tighter"
            >
              {title}
            </ResizeabeTitle>
          )}
          {intro && (
            <div css={[S.container, S.txtCenter, "mx-auto"]}>
              <MarkdownBlock content={intro} />
            </div>
          )}
          {Array.isArray(stats) && (
            <div className="mt-24 grid w-full grid-cols-[repeat(auto-fit,_minmax(240px,1fr))] justify-items-center gap-14">
              {stats.map((stat, index) => (
                <StatItem key={index} imageStyle={imageStyle} {...stat} />
              ))}
            </div>
          )}
          {get(cta, "url") && (
            <div
              className="mt-20 flex justify-center"
              style={{ "--button-min-w": "300px" }}
            >
              <Btn {...cta} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

StatsBlock.displayName = "StatsBlock";
StatsBlock.defaultProps = {
  variation: "red",
};

export default StatsBlock;

export const statsBlockField = {
  label: "Stats",
  name: "statsBlock",
  widget: "object",
  fields: [
    {
      label: "Variation",
      name: "variation",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "White w/ Grid", value: "whiteGrid" },
        { label: "Gray", value: "gray" },
        { label: "Gray w/ Grid", value: "grayGrid" },
        { label: "Red", value: "red" },
      ],
      default: "white",
    },
    {
      label: "Doodles",
      name: "doodles",
      widget: "boolean",
      default: true,
      required: false,
    },
    {
      ...transformField,
      label: "Doodle 1",
      name: "doodle1",
    },
    {
      ...transformField,
      label: "Doodle 2",
      name: "doodle2",
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Intro",
      name: "intro",
      widget: "markdown",
      required: false,
    },
    {
      label: "Image Background",
      name: "imageStyle",
      widget: "select",
      options: [
        { label: "Yes", value: "solid" },
        { label: "No", value: "none" },
      ],
      default: "solid",
    },
    {
      label: "Stats",
      name: "stats",
      widget: "list",
      fields: [
        {
          ...imageField,
          required: false,
        },
        {
          label: "Stat Text",
          name: "statText",
          widget: "string",
          required: false,
        },
        {
          label: "Content",
          name: "content",
          widget: "markdown",
          required: false,
        },
      ],
    },
    {
      label: "CTA",
      name: "cta",
      widget: "object",
      fields: get(btnFields, "fields"),
      required: false,
    },
  ],
};

export const statsBlockFragment = graphql`
  fragment StatsBlockContent on statsBlock {
    variation
    doodles
    doodle1 {
      ...TransformBox
    }
    doodle2 {
      ...TransformBox
    }
    title
    titleMaxWidth
    intro
    imageStyle
    stats {
      imageUrl
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 400
            aspectRatio: 1
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
          )
        }
      }
      statText
      content
    }
    cta {
      ...CTA
    }
  }
`;
