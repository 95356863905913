import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import PardotForm, { pardotFormFields } from "app/components/pardot-form";
import MarkdownBlock from "app/components/markdown-block";

import * as S from "app/components/modular-content/modular-content.styles";

const alignmentStyles = {
  left: "text-left items-start",
  center: "text-center items-center",
  right: "text-right items-end",
};

const PardotFormBlock = ({ title, content, alignment, pardotForm }) => {
  return (
    <section css={S.moduleTheme}>
      <div
        className={`my-0 mx-auto flex w-full max-w-7xl flex-col justify-center py-20 px-[var(--gutter)] ${get(
          alignmentStyles,
          alignment
        )}`}
      >
        {title && (
          <h2 className="mb-5 text-4xl font-bold tracking-tighter" css={[S.container, S.containerNarrower]}>{title}</h2>
        )}
        <div css={[S.container, S.containerNarrower]}>
          <MarkdownBlock content={content} />
        </div>
        <PardotForm {...pardotForm} />
      </div>
    </section>
  );
};

PardotFormBlock.displayName = "PardotFormBlock";

export default PardotFormBlock;

export const pardotBlockField = {
  label: "Pardot Form Block",
  name: "pardotFormBlock",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Content",
      name: "content",
      widget: "markdown",
      required: false,
    },
    {
      label: "Alignment",
      name: "alignment",
      widget: "select",
      options: ["left", "center", "right"],
      default: "center",
    },
    {
      label: "Pardot Form",
      name: "pardotForm",
      widget: "object",
      fields: pardotFormFields,
    },
  ],
};

export const pardotFormBlockFragment = graphql`
  fragment PardotFormBlockContent on pardotFormBlock {
    title
    content
    alignment
    pardotForm {
      ...PardotForm
    }
  }
`;
