import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const Carousel = ({ items, Component, ...props }) => {
  if (!Array.isArray(items) || !items.length || !Component) {
    return null;
  }

  return (
    <Swiper
      modules={[Pagination, A11y, EffectFade]}
      pagination={{
        clickable: true,
      }}
      effect="fade"
      fadeEffect={{ crossFade: true }}
      loop
      {...props}
    >
      {items.map((slide, idx) => (
        <SwiperSlide key={idx}>
          {React.cloneElement(Component, slide)}
        </SwiperSlide>
      ))}

      <span slot="container-end">
        <div className="pt-20"></div>
      </span>
    </Swiper>
  );
};

Carousel.displayName = "Carousel";

export default Carousel;
