import React from "react";
import { graphql } from "gatsby";
import get from "lodash.get";

import Link from "app/components/link";
import Image from "app/components/image";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as ModularStyles from "app/components/modular-content/modular-content.styles";
import * as GridStyles from "app/components/modular-content/block-grid.styles";

const S = {
  ...ModularStyles,
  ...GridStyles,
};

const gridImageStyle = ["wide", "square", "square", "tall", "wide", "wide"];

const GridItem = ({
  image,
  imageUrl,
  title,
  subtitle,
  link,
  index,
  gridStyles,
}) => {
  const imageStyle = get(gridImageStyle, index);
  const bgImage = get(image, imageStyle);

  return (
    <Link
      href={link}
      css={[S.gridItem, gridStyles[index]]}
      optional
      fallbackAs="article"
    >
      <div css={[S.relative, S.z5]}>
        <h3 css={[S.bold, S.text4xl, S.mb4]}>{title}</h3>
        {subtitle && <p css={[S.bold, S.textBase, S.uppercase]}>{subtitle}</p>}
      </div>
      {(bgImage || imageUrl) && (
        <Image image={bgImage} imageUrl={imageUrl} css={S.bgImage} />
      )}
    </Link>
  );
};

GridItem.displayName = "GridItem";

const GridBlock = ({ title: moduleTitle, titleMaxWidth, items }) => (
  <section css={[S.container, S.txtCenter, S.py20]}>
    {moduleTitle && (
      <ResizeabeTitle
        maxWidth={titleMaxWidth}
        css={[S.text4xl, S.bold, S.mb16]}
      >
        {moduleTitle}
      </ResizeabeTitle>
    )}
    {Array.isArray(items) && !!items.length && (
      <div css={[S.grid, items.length < 4 && S.gridSmall]}>
        {items.map((props, index) => (
          <GridItem
            key={index}
            {...props}
            index={index}
            gridStyles={items.length > 2 ? S.items : S.items2Col}
          />
        ))}
      </div>
    )}
  </section>
);

GridBlock.displayName = "GridBlock";

export default GridBlock;

export const gridBlockField = {
  label: "Grid",
  name: "gridBlock",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Items",
      name: "items",
      widget: "list",
      min: 1,
      max: 6,
      fields: [
        {
          label: "Background Image",
          name: "image",
          widget: "image",
          required: false,
        },
        {
          label: "Title",
          name: "title",
          widget: "string",
        },
        {
          label: "Subtitle",
          name: "subtitle",
          widget: "string",
          required: false,
        },
        {
          label: "Link",
          name: "link",
          widget: "string",
          required: false,
        },
      ],
    },
  ],
};

export const gridBlockFragment = graphql`
  fragment GridBlockContent on gridBlock {
    title
    titleMaxWidth
    items {
      imageUrl
      image {
        publicURL
        wide: childImageSharp {
          gatsbyImageData(
            width: 1200
            height: 800
            # placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
        tall: childImageSharp {
          gatsbyImageData(
            width: 800
            height: 1200
            #placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
        square: childImageSharp {
          gatsbyImageData(
            width: 800
            height: 800
            # placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
      }
      title
      subtitle
      link
    }
  }
`;
