const isEmpty = (v) => v === undefined || v === "" || v === null;

export const removeEmptyKeys = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return {};
  }

  return Object.entries(obj).reduce(
    (a, [k, v]) => (isEmpty(v) ? a : ((a[k] = v), a)),
    {}
  );
};
