import React from "react";
// import { graphql } from "gatsby";
import _get from "lodash.get";

import Layout from "app/components/layout";
import SEOBlock, { seoFields } from "app/components/seo";
import ModularContent, {
  modularContentField,
} from "app/components/modular-content/modular-content";

const PageTemplate = (data) => (
  <Layout>
    <SEOBlock {..._get(data, "seo", {})} />
    <ModularContent {...data} />
  </Layout>
);

export default PageTemplate;

export const topicPageFields = [seoFields, modularContentField];
